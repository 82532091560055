.DrawerContentBlocksSorting {
  &__title {
    font-weight: var(--font-weight-bold);
    font-size: 14px;
    color: var(--color-grey-even-darker);
    padding: 0 1.5rem;
  }

  &__description {
    font-size: var(--font-size-input);
    color: var(--color-grey-even-darker);
    padding: 0 1.5rem;
  }

  &__empty {
    padding: 0 1.5rem;
    color: var(--emphasis-medium);
    font-size: 12px;
    text-align: center;
  }
}
