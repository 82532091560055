.FieldBox {
  cursor: move;
  padding: 0.5rem;
  border: 1px solid white;
  /* Blocks are within 1000-2000 range */
  z-index: 2000;
  border-radius: 5px;
  transition: box-shadow 0.25s ease;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }

  &::after {
    position: absolute;
    content: '';
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    cursor: move;
    z-index: 1;
  }

  &__dataPreview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__dataGlyph {
    width: 24px;
    height: 24px;
    & > svg {
      width: 16px;
      height: 16px;
    }
  }

  &__remove {
    position: absolute;
    background: transparent;
    fill: var(--emphasis-medium);
    top: 1px;
    right: 1px;
    cursor: pointer;
    width: 22px;
    height: 22px;
    padding: 4px;
    z-index: 2;

    &:hover {
      fill: var(--emphasis-high);
    }

    & > svg {
      width: 16px;
      height: 16px;
    }
  }
}
