.DraggableBox {
  cursor: move;

  &__handleTop,
  &__handleBottom,
  &__handleLeft,
  &__handleRight {
    position: absolute;
    background-color: transparent;
    z-index: 100;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &__handleTop,
  &__handleBottom {
    cursor: ns-resize;
    height: 4px;
    width: 100%;
  }

  &__handleTop {
    left: 0;
    top: 0px;
  }

  &__handleBottom {
    left: 0;
    bottom: 0px;
  }

  &__handleLeft,
  &__handleRight {
    cursor: ew-resize;
    width: 4px;
    height: 100%;
  }

  &__handleLeft {
    left: 0px;
    top: 0px;
  }

  &__handleRight {
    top: 0px;
    right: 0px;
  }
}
