.ContributeAnswerDetailTemplate {
  width: 30%;
  height: 100%;

  &__info {
    &__column {
      display: flex;
      flex-direction: row;
    }

    &__row {
      display: flex;
      flex-direction: column;
      flex: 1 0 50%;
      text-align: center;
      & > span {
        color: var(--color-grey-dark);
      }
    }
  }
}
