.Paragraph {
  & > h3 {
    font-size: 14px;
  }

  & > p {
    font-size: var(--font-size-input);
  }

  &__btn {
    display: inline-block;
    padding: 0 2px;
    height: 100%;

    & > svg {
      height: 14px;
      width: 14px;
      vertical-align: middle;
    }
  }
}
