.DatetimeRangeField__inputs {
  box-sizing: border-box;
  max-width: 100%;

  & > *:first-child {
    margin-right: 8px;
  }
  & > * {
    display: inline-block;
    max-width: 48%;
  }
}
