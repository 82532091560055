.WYSIWYGGridPage {
  padding: 72px 2rem;
  box-sizing: border-box;

  &__emptyState {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s ease;

    &--visible {
      opacity: 1;
    }
  }

  &__emptySVG {
    width: 624px;
    height: 448px;
    max-width: 100%;
  }

  &__title {
    margin-top: 24px;
    margin-left: 24px;
    font-size: 32px;
  }
}
