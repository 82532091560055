.TextFieldHoverContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.TextFieldHover {
  position: absolute;

  margin-top: 4px;
  padding: 8px;

  max-height: 250px;

  font-size: calc(var(--font-size-input));

  background: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

  text-overflow: ellipsis;
  white-space: wrap;

  overflow: auto;
}