.KeyboardShortcutsForm {
  &__shortcut {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      width: 45%;
      margin-right: 4px;
    }
  }

  &__deleteButton {
    padding: 0;
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}
