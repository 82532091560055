.EditItemTemplate {
  &__buttons {
    margin-bottom: 1.5rem;
  }

  &__newButton {
    background: transparent;
    position: relative;

    & > svg {
      position: absolute;
      left: 1rem;
    }

    &:hover:not(&--flat) {
      background: transparent;
    }
  }

  &__typology.EditItemTemplate__typology {
    background: transparent;
    margin-bottom: 1rem;
  }
}
