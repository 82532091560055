.DrawerHeader {
  padding: 1rem 1rem 0rem 1rem;

  &:after {
    display: block;
    content: '';
    width: 100%;
    margin-top: 1rem;
    border-bottom: 2px solid var(--color-drawer-border);
  }
}
