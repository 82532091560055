.QualitySelectTemplate {
  padding: 1rem;
  padding-bottom: 0;
  position: relative;

  &__button {
    cursor: pointer;
    background-color: var(--color-white);

    & > svg {
      width: 9px;
      height: 6px;
      padding-right: 0.74rem;
    }
  }

  &__menu {
    box-shadow: 0 5px 22px 3px var(--color-blue-dark);
    border-radius: 10px;
    background-color: var(--color-white);
    position: absolute;
    top: 0;
    z-index: 1000;
    width: calc(100% - 2rem);
    padding: 0.5rem;
    box-sizing: border-box;
  }

  &__menuItem {
    border: none;

    &:hover {
      background: var(--color-drawer-border);
    }
  }
}
