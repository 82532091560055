.InfoButton {
  background-color: var(--color-white);
  border: 1px solid var(--color-font);
  border-radius: var(--border-radius-semi-round);
  position: fixed;
  right: 0;
  margin-right: 16px;
  margin-top: 16px;
  height: 40px;
  width: 40px;
  z-index: var(--z-layer-infodrawer);
  padding: 11.5px;

  &:hover:not(&--flat) {
    background-color: var(--color-white);
  }

  & > svg {
    display: inline-flex;
    align-self: center;
    fill: var(--color-font);
  }
}
