.VideoPlayerData {
  border-radius: var(--border-radius);
  background-color: var(--color-white);
  height: 100%;
  width: 100%;

  &__Player {
    border-radius: var(--border-radius);
    height: 100%;
    width: 100%;
  }
}
