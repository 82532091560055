.Error {
  &--fill {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__title {
    font-weight: var(--font-weight-bold);
    color: var(--color-error);
    margin-bottom: 1rem;
  }

  &__body {
    color: var(--color-error);
  }
}
