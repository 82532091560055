.AddressField__optionFlag {
  height: 12px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-left: 6px;
}

  .selectStreet {
    border-radius: 0 0 10px 10px;
    height: 50px;
  }

.googleApiActive {
  border-radius: 10px 10px 0 0px;
}
