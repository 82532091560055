.FormulaDropdown {
  position: relative;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: var(--color-white);
    top: 0;
    padding: 0.5rem;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 var(--color-shadow);
    z-index: 1;
  }

  &__item {
    border: none;
    background: transparent;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 3px;
    font-size: var(--font-size);
    text-align: left;
    white-space: nowrap;

    &:hover {
      background: var(--color-drawer-border);
    }
  }

  &__button {
    cursor: pointer;
    border: none;
    width: 24px;
    height: 24px;
    background: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 3px;
  }

  &__icon {
    width: 16px;
    height: 16px;
    fill: #000000;
  }
}
