.Item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: var(--emphasis-medium);
  background-color: var(--color-white);
  border: 1px solid var(--color-drawer-border);
  border-radius: var(--border-radius-semi-round);
  height: 3.6rem;
  line-height: 3.6rem;
  letter-spacing: 0;
  padding: 0 0.25rem;
  margin: 0.5rem 0;

  & div {
    width: 2.25rem;
    height: 2.25rem;
    margin: 0.6rem 0.5rem;
    border-radius: var(--border-radius-semi-round);
  }

  & > div > svg {
    height: 18px;
    width: 18px;
  }

  &__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  &__iconRight {
    width: 24px;
    height: 24px;
    fill: var(--emphasis-medium);
  }
}
