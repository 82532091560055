.Select {
  outline: none;
  box-sizing: border-box;
  height: var(--height);
  width: 100%;
  border: 1px solid var(--color-grey);
  border-radius: var(--border-radius-semi-round);
  background-color: var(--color-white);
  padding-left: 0.8rem;
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, var(--color-grey-dark) 50%),
    linear-gradient(135deg, var(--color-grey-dark) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}
