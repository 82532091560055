.Spinner {
  width: 64px;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.3);
  border-radius: var(--border-radius-semi-round);
  padding: 10px;

  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.7s infinite ease-in-out;
  animation: sk-rotateplane 1.7s infinite ease-in-out;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  25% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
  75% {
    transform: perspective(120px) rotateX(0) rotateY(-180deg);
    -webkit-transform: perspective(120px) rotateX(0) rotateY(-180deg);
  }
}
