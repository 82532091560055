.MCQ {
  display: flex;
  font-family: var(--font-body);
  font-size: var(--font-size-input);
  font-weight: 500;

  &--vertical {
    flex-direction: column;
  }

  &--horizontal {
    flex-direction: row;
  }

  &--other-input {
    margin: 0 12px;
    width: 100%;
    width: -moz-available; /* For Mozzila */
    width: -webkit-fill-available; /* For Chrome */
    width: stretch;
  }

  &__option {
    display: flex;
    align-items: center;
  }

  &__option--horizontal {
    flex: 1;
  }

  &--vertical > &__option > &--radio + label {
    margin-bottom: 0.5rem;
  }

  &--horizontal > &__option > &--radio + label {
    margin-right: 1.69rem;
  }

  &--radio {
    display: none;

    & + label {
      display: flex;
      color: var(--color-grey-even-darker);

      &:before {
        content: '';
        flex: none;
        cursor: pointer;
        width: 14px;
        height: 14px;
        margin: auto 2.42px;
        border-radius: 50%;
        border: 0.1em solid var(--color-blue-light);
        transition: 180ms all ease-in-out;
      }
    }

    &:checked + label:before {
      background: radial-gradient(var(--color-blue-light) 50%, var(--color-white) 60%);
    }
  }
}
