.Button {
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--gutter);
  height: 2.5rem;
  border-radius: var(--border-radius-semi-round);
  font-size: var(--font-size-input);
  color: var(--emphasis-high);
  background-color: var(--color-primary);
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  transition: background-color 0.5s ease;
  text-decoration: none;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  &:hover:not(&--flat) {
    background-color: var(--color-button-hover);
  }

  &--button {
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  &--block {
    width: 100%;
  }

  &--rounded {
    border-radius: 1.25rem;
    padding: var(--gutter) 1.5rem;
  }

  &__Icon {
    height: 18px;
    width: 18px;
  }

  &--disabled {
    cursor: not-allowed;

    & > svg {
      color: var(--emphasis-high);
    }
  }

  &--flat {
    background-color: transparent;
    color: var(--emphasis-high);

    & > svg {
      fill: var(--color-font);
    }
  }

  &--theme-white {
    color: var(--color-white);

    & > svg {
      fill: var(--color-white);
    }
  }

  &--active,
  &:global(.active) {
    background-color: var(--emphasis-high);
    color: var(--color-white);

    & > svg {
      fill: var(--color-white);
    }
  }

  &--theme-white.Button--active,
  &--theme-white.Button:global(.active) {
    background-color: var(--color-white);
    color: var(--color-primary);

    & > svg {
      fill: var(--color-focus);
    }
  }
}
