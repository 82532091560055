.NoCompare {
  border: 1px solid var(--color-drawer-border);
  border-radius: 4px;
  background: var(--color-white);
  min-height: 24px;
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;

  &__placeholder {
    color: var(--emphasis-medium);
    font-size: 12px;
  }

  &__token {
    background: var(--color-grey-even-darker);
    color: var(--color-white);
    padding: 4px 8px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    height: 20px;
    cursor: move;

    &:not(:last-child) {
      margin-right: 8px;
      margin-bottom: 4px;
    }

    &_icon {
      width: 16px;
      height: 16px;
      fill: var(--color-grey);
    }

    &_button {
      border: none;
      background: transparent;
      margin-left: 8px;
      padding: 0;
      cursor: pointer;
    }
  }
}
