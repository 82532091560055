.DrawerDetail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(241, 244, 250, 0.5);
  width: 100%;
  height: inherit;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgb(189 183 183 / 25%);
}
