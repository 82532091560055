.QualityNavTemplate {
  &__item_color {
    background-color: var(--color-quality-icon);
  }

  &__a {
    text-decoration: none;
  }

  &__item {
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      background-color: var(--color-grey);

      & > span {
        color: var(--emphasis-disabled);
      }

      /* double CSS class to override priority */
      &:hover.QualityNavTemplate__item--disabled:hover {
        background-color: var(--color-grey);
      }
    }
  }
}
