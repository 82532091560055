.Textarea {
  outline: none;
  box-sizing: border-box;
  border: 1px solid var(--color-grey);
  border-radius: var(--border-radius-semi-round);
  color: var(--color-grey-dark);
  font-size: var(--font-size-input);
  letter-spacing: 0;
  line-height: 12px;
  padding: 0.3rem 0.5rem;
  cursor: text;
  resize: none;
  font-family: var(--font-body);
  width: 100%;

  &:focus:not(:read-only) {
    border-color: var(--color-focus);
  }

  &--error,
  &--error:focus {
    border-color: var(--color-error);
  }
}
