.FormulaToken {
  background: var(--color-grey-even-darker);
  color: var(--color-white);
  padding: 4px 8px;
  display: flex;
  align-items: flex-start;
  line-height: 16px;
  border-radius: 3px;
  min-height: 20px;
  font-size: 12px;
  cursor: move;
  position: relative;

  &--open {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 4px;
  }

  &__icon {
    width: 16px;
    height: 16px;
    fill: var(--color-grey);
  }

  &__button {
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline: none;

    &--left {
      margin-left: 8px;
    }

    &--right {
      margin-right: 8px;
    }

    &--menuOpen {
      & > svg {
        transform: rotate(90deg);
      }
    }
  }

  &__menu {
    position: absolute;
    z-index: 100;
    background: var(--color-white);
    width: 100%;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 2px 12px 0 var(--color-shadow);
    top: 100%;
    left: 0;
    padding: 0.5rem;
    box-sizing: border-box;
  }

  &__menuItem {
    border: none;
    font-size: 12px;
    border-radius: 2px;
    background: var(--color-white);
    cursor: pointer;
    text-align: left;
    outline: none;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &:hover {
      border-radius: 3px;
      background: var(--color-grey);
    }
  }
}
