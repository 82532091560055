.DrawerFormControls {
  overflow-x: hidden;
  max-width: 100%;
  justify-content: space-between;
  
  .previewFooter__button {
    background: transparent;
    padding: 8px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      background: transparent;
    }
  }
  .DrawerFormControl__button {
    background: #7057FF;
    border-radius: 10px;
    padding: 16px;
    margin-left: 4px;
  }
}
