.DrawerContentBlocks {
  &__title {
    font-weight: var(--font-weight-bold);
    font-size: 14px;
    color: var(--color-grey-even-darker);
    padding: 0 1.5rem;
  }

  &__description {
    font-size: var(--font-size-input);
    color: var(--color-grey-even-darker);
    padding: 0 1.5rem;
  }

  &__stubWrapper {
    padding: 1.5rem;
  }

  &__stub {
    background: var(--radio-color-1);
    padding: 1rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
    cursor: -webkit-grab;
  }

  &__stubIcon {
    width: 24px;
    height: 24px;
    fill: var(--radio-color-1-dark);
  }

  &__stubIconWrapper {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background: white;
    padding: 4px;
  }
}
