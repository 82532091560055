.UploadInput {
  &__input {
    display: flex;
    overflow: hidden;
  }

  &__button {
    color: var(--color-white);
    background: var(--color-grey-even-darker);
    font-size: 10px;
    font-weight: normal;
    text-transform: none;
    padding: 4px 8px;
    height: 24px;
    border-radius: 4px;

    &:hover.UploadInput__button {
      background: var(--color-primary-dark);
    }

    & > svg {
      fill: var(--color-white);
      margin-right: 8px;
    }
  }

  &__preview {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__clearButton {
    height: 24px;
    padding: 4px;
  }

  &__fileMeta {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
  }

  &__spinner {
    margin: 4px;
    fill: var(--emphasis-medium);
    width: 16px;
    height: 16px;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
