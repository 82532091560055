.DrawerVerticalNav {
  background-color: var(--color-focus);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  width: 55px;

  &__brand {
    width: 34px;
  }

  &__links {
    margin: 2rem 0.5rem 0 0.5rem;

    & > button:not(:first-child) {
      margin: 1rem 0.5rem 0 0.5rem;
    }
  }

  &__back {
    /** seems wrong but made for alignment with drawerheader */
    margin-top: 41px;
  }
}
