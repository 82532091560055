.ContributeFormErrorModal {
  &__description {
    color: var(--color-error);
  }

  &__errors {
    list-style-type: none;
    padding: 0;
  }

  &__name {
    margin-bottom: 0;
    font-weight: var(--font-weight-bold);
  }

  &__errorMessage {
    margin-top: 0;
  }

  &__actions {
    display: flex;
    justify-content: center;
  }

  &__actionPane {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__forceButton {
    background: none;
    border: none;
    font-family: var(--font-body);
    font-size: 12px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
