.DraggableBoxStub {
  cursor: move;
  border-radius: var(--border-radius);
  z-index: 100;
  background-color: var(--color-drawer);
  border: px solid var(--color-white-forward);
  transition: box-shadow 0.5s ease;

  &:hover {
    box-shadow: 0 2px 12px 0 var(--color-shadow);
  }
}
