.DrawerNavTemplate {
  &__button {
    width: 40px;
    padding: var(--semi-gutter);
    margin-bottom: 0.75rem;
  }
  &__Popover {
    width: 50%;
    word-wrap: break-word;
    padding: 12px;
  }
  &__PopoverTitle {
    color: var(--color-light-purple);
  }
}
