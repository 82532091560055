.RegexRuleFragment {
  margin-bottom: 1em;

  &__help {
    color: var(--emphasis-medium);
    font-size: 12px;
    position: relative;
    top: -1rem;
  }
}
