.AdvancedToCompare {
  padding: 0 1.5rem;

  &__title {
    font-weight: var(--font-weight-bold);
    font-size: 14px;
    color: var(--color-grey-even-darker);
  }

  &__description {
    font-size: var(--font-size-input);
    color: var(--color-grey-even-darker);
  }

  &__backButton {
    background: transparent;
    position: relative;

    & > svg {
      position: absolute;
      left: 1rem;
    }

    &:hover:not(&--flat) {
      background: transparent;
    }
  }

  &__itemPlaceholder {
    margin-bottom: 1rem;
  }

  &__clickable {
    cursor: pointer;
  }

  &__empty {
    color: var(--emphasis-medium);
    font-size: 12px;
    text-align: center;
  }
}
