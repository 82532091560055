.PdfOcrViewer {
  width: 100%;
  height: 100%;

  overflow: auto;

  background-color: #5e686e;
}
  

.PdfOcrViewerPage {
  position: relative;

  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;

  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}