.Rating {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  & > span {
    margin: auto 1rem;
  }

  &__star {
    fill: var(--color-star);
    width: 24px;
    height: 24px;

    &--active {
      fill: var(--color-star-active);
    }
  }
}
