.Drawer {
  min-width: var(--width-drawer);
  max-width: var(--width-drawer);
  height: 100%;
  background: var(--color-drawer);
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  border-right: 1px solid var(--color-drawer-border);
}
