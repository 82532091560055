.Switch {
  appearance: none;
  outline: none;
  cursor: pointer;

  position: relative;
  width: 2rem;
  height: 1rem;
  margin: 0;
  border-radius: 20px;
  background: var(--color-grey);

  &:checked {
    background: var(--color-blue-light);
  }

  &::before {
    content: '';
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    background: var(--color-white);
    border-radius: 20px;
    top: 0.155rem;
    left: 0.2rem;
    transition: all 0.3s;
    appearance: none;
  }

  &:checked::before {
    left: 1.1rem;
  }
}
