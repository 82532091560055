.Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  min-height: 1.8rem;
  background-color: var(--color-primary);
  box-sizing: border-box;
  border: none;

  &--button {
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  &--flat {
    background-color: transparent;
  }

  &__SVG {
    width: 24px;
    height: 24px;
    fill: var(--color-white);

    &--flat {
      fill: var(--color-primary);
    }
  }
}
