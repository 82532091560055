.Popover {
  position: absolute;
  z-index: 999999;
  box-sizing: border-box;
  padding: 12px;

  border-radius: 10px;
  background-color: var(--color-white);
  box-shadow: 0 5px 22px 3px var(--color-blue-dark);
  max-width: 600px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--hidden {
    visibility: hidden;
  }

  &[direction='right'] {
    :before {
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
      left: -20px;
      top: calc(90% / 2);
      border: 10px solid transparent;
      border-right-color: var(--color-tooltip);
    }
  }
  &[direction='left'] {
    :before {
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
      left: 600px;
      top: calc(90% / 2);
      border: 10px solid transparent;
      border-left-color: var(--color-tooltip);
    }
  }
  &[direction='top'] {
    :before {
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
      left: 300px;
      top: 219px;
      border: 10px solid transparent;
      border-top-color: var(--color-tooltip);
    }
  }
  &[direction='bottom'] {
    :before {
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
      left: 300px;
      top: -20px;
      border: 10px solid transparent;
      border-bottom-color: var(--color-tooltip);
    }
  }
}
