.BlockControls {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  box-sizing: border-box;

  /* Although passed _after_ button class we need an override here */
  &__button.BlockControls__button {
    background: var(--color-white);
    padding: 0;
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    margin-left: 4px;

    & > svg {
      fill: var(--radio-color-1-dark);
    }

    &:hover {
      background-color: var(--color-grey);
    }
  }

  &__buttonFold {
    background: var(--color-white);
    padding: 0;
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    border-radius: 14px;
    transform: rotate(90deg);
    transition: transform 0.25s ease, background 0.25s ease;

    &--folded {
      transform: rotate(0deg);
    }

    &.BlockControls__buttonFold:hover {
      background: var(--color-grey);
    }

    & > svg {
      fill: var(--radio-color-1-dark);
    }
  }

  &__flex {
    flex: 1;
  }

  &__name {
    background: var(--color-white);
    border-radius: 12px;
    line-height: 28px;
    padding: 0 0.5rem;
    height: 28px;
    color: var(--radio-color-1-dark);
    margin-right: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__review {
    display: flex;
    align-items: center;
  }
}
