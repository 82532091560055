.DrawerDetailFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 20%;
  padding: 1rem;

  & > button {
    margin: 0 auto;
  }
}
