.CompareBox {
  border: 1px solid var(--color-drawer-border);
  background: var(--color-white);
  border-radius: 4px;
  display: flex;
  margin-bottom: 1rem;

  &--focused {
    border-color: var(--color-focus);
  }

  &__body {
    padding: 0.5rem;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  &__actions {
    padding: 0.5rem;
    background: var(--color-drawer-border);
  }

  &__button {
    background: var(--color-white);
    padding: 2px;
    width: 24px;
    height: 24px;
    border-radius: 2px;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
