.PDFViewer {
  width: 100%;
  height: 100%;

  &__embed {
    width: 100%;
    height: 100%;

    &--fixed {
      width: inherit;
      height: inherit;
    }
  }
}
