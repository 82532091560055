.BlockSortingRulesTemplate {
  padding: 1rem 1.5rem;

  &__actions {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  &__empty {
    padding: 0 1.5rem;
    color: var(--emphasis-medium);
    font-size: 12px;
    text-align: center;
  }
}
