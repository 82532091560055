.Dropdown {
  &__button {
    /* by default dropdowns use a flat, compact button */
    background: transparent;
    padding: 0 0.25rem 0.25rem 0.25rem;
    height: auto;

    /* the class is repeated to have higher priority than button default style */
    .Dropdown__button&:hover {
      background-color: transparent;
    }
  }

  &__menu {
    z-index: 1000;
    position: fixed;
    box-shadow: 0 2px 12px 0 var(--color-shadow);
    background: var(--color-white);
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
  }

  &__option {
    background: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 2px;
    transition: background 0.25s ease;
    outline: none;

    &:hover {
      background: var(--color-drawer-border);
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__empty {
    font-size: 12px;
    color: var(--emphasis-medium);
  }
}
