.SelectTag {
  background: var(--color-grey-even-darker);
  color: var(--color-white);
  font-size: 10px;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  border-radius: 6px;

  &__remove {
    border: none;
    background: transparent;
    cursor: pointer;
    margin-left: 0.75rem;
    width: 16px;
    height: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__removeIcon {
    fill: var(--color-white);
    width: 12px;
    height: 12px;
    display: block;
  }
}
