.CompareTag {
  background: var(--color-grey-even-darker);
  color: var(--color-white);
  padding: 4px 8px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  height: 20px;
  font-size: 12px;
  cursor: move;

  &:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 4px;
  }

  &__icon {
    width: 16px;
    height: 16px;
    fill: var(--color-grey);
  }

  &__button {
    border: none;
    background: transparent;
    margin-left: 8px;
    padding: 0;
    cursor: pointer;
    outline: none;
  }
}
