.AudioPlayer {
  display: flex;
  align-items: center;

  &__button {
    background: transparent;
    border: 2px solid var(--color-grey-dark);
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__buttonIcon {
    fill: var(--color-grey-dark);
    width: 16px;
    height: 16px;
  }

  &__progressBar {
    margin-left: 0.5rem;
    height: 16px;
    width: 100%;
    border-radius: 1px;
    cursor: pointer;
    position: relative;

    &::before {
      display: block;
      content: '';
      width: 100%;
      height: 2px;
      position: relative;
      top: 6px;
      background: var(--color-grey);
    }

    &::after {
      /** overlay to catch clicks */
      display: block;
      content: '';
      width: 100%;
      position: absolute;
      z-index: 1;
      height: 100%;
      top: 0;
    }
  }

  &__progressBarIndicator {
    position: relative;
    top: 4px;
    height: 2px;
    background: var(--color-focus);
  }

  &__progressHandle {
    width: 4px;
    height: 4px;
    background: var(--color-focus);
    position: absolute;
    margin-top: 1px;
    margin-left: -1px;
    border-radius: 50%;
  }
}
