.ValidationRule {
  &__header {
    display: flex;
    flex: 1;
    align-items: center;

    & > *:first-child {
      flex: 1;
    }
  }

  &__deleteButton {
    padding: 2px;
    position: relative;
    left: 8px;

    & > svg {
      fill: var(--emphasis-medium);
    }
  }
}
