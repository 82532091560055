.IframeViewer {
    width: 100%;
    height: 100%;
  
    &__iframe {
      width: 100%;
      height: 100%;
  
      &--fixed {
        width: inherit;
        height: inherit;
      }
    }
  }
  