.AdvancedSelectFragment {
  & > * {
    margin-bottom: 1rem;
  }

  &__help {
    font-size: 12px;
    color: var(--emphasis-medium);

    & > a {
      color: var(--color-purple);
      text-decoration: none;
    }
  }
}
