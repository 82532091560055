.ContributeAnswerFormTemplate {
  padding: 2rem;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: 0 30px;
  box-shadow: 0px 0px 10px rgb(189 183 183 / 25%);
  border-radius: 8px;

  &__actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 1rem;
    background: #efefef;
    box-sizing: border-box;
  }

  &__hiddenSubmit {
    position: fixed;
    top: -1000px;
    left: -1000px;
  }
}
