.Modal {
  background: var(--color-white);
  box-shadow: 0 5px 22px 3px var(--color-blue-dark);
  padding: 32px 24px;
  border-radius: 6px;
  font-family: var(--font-body);
  min-width: 256px;
  z-index: 10000;
  margin: auto;

  &__scrim {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-scrim);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    overflow-y: scroll;
  }

  &__title {
    font-weight: var(--font-weight-bold);
    color: var(--emphasis-high);
    margin: 0;
    margin-bottom: 16px;
    font-size: 26px;
    font-family: var(--font-body);
  }
}
