.App {
  height: 100%;
  max-width: 100%;
  display: flex;
  position: relative;

  &__main {
    flex: 1;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
