.DatetimeField {
  &__dateAndTime {
    display: flex;
    align-items: center;

    & > *:first-child:after {
      content: '';
      display: block;
      width: 0.5rem;
    }
    & > * {
      width: 50%;
    }
  }
}
