.Spoiler {
  &__header {
    height: 56px;
    border-bottom: 1px solid var(--color-drawer-border);
    display: flex;
    align-items: center;
    padding: 0 1rem;
    cursor: pointer;
    user-select: none;

    &:first-child {
      border-top: 1px solid var(--color-drawer-border);
    }
  }

  &__caret {
    width: 9px;
    height: 6px;
    margin-right: 0.55rem;
    transform: rotate(-90deg);

    &--open {
      transform: rotate(0deg);
    }
  }
}
