.RadioColorInput {
  display: flex;
  padding: 2px;

  &__bullet {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid var(--color-white);
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 0.5rem;
    }

    &--selected {
      box-shadow: 0px 0px 0 2px var(--color-focus);
    }
  }
}
