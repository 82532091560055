.BlockItemControls {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  box-sizing: border-box;

  &__button.BlockItemControls__button {
    background: white;
    padding: 0;
    width: 24px;
    height: 24px;
    margin-left: 4px;

    & > svg {
      fill: var(--radio-color-1-dark);
    }
  }

  &__flex {
    flex: 1;
  }

  &__separator  {
    display: block;
    height: 2px;
    border: 0;
    margin: 1em 48px;
    padding: 0;
    width: 100%;
  }
}
