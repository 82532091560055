.FormulaInput {
  border: 1px solid var(--color-drawer-border);
  box-sizing: border-box;
  border-radius: 4px;

  &--error {
    border-color: var(--color-error);
  }

  &__toolbar {
    padding: 4px;
    background: var(--color-drawer-border);
    display: flex;
    flex-wrap: wrap;
  }

  &__body {
    padding: 8px 12px;
    display: flex;
    flex-wrap: wrap;
  }

  &__placeholder {
    color: var(--color-primary-disabled);
    user-select: none;
  }
}
