.AdvancedSelect__tags {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;

  & > * {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
}
