.ItemList {
  height: 100%;
  max-width: 100%;
  display: flex;
  &__title {
    font-weight: var(--font-weight-bold);
    font-size: 14px;
    color: var(--color-grey-even-darker);
  }

  &__description {
    font-size: var(--font-size-input);
    color: var(--color-grey-even-darker);
    padding-bottom: 0.75rem;
  }
}
