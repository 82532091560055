:global {
  .Toastify__toast--default {
    background-color: var(--color-white) !important;
    color: var(--color-grey-dark) !important;
    min-height: 40px !important;
    border-left: 5px solid var(--color-grey-dark) !important;
    border-radius: 5px !important;
  }
  .Toastify__toast--error {
    background-color: var(--color-light-red) !important;
    color: var(--color-red) !important;
    min-height: 40px !important;
    border-left: 5px solid var(--color-red) !important;
    border-radius: 5px !important;
  }
  .Toastify__toast--success {
    background-color: var(--color-light-green) !important;
    color: var(--color-green) !important;
    min-height: 40px !important;
    border-left: 5px solid var(--color-green) !important;
    border-radius: 5px !important;
  }
  .Toastify__toast--warning {
    background-color: #ffecc7 !important;
    color: #ffab00 !important;
    min-height: 40px !important;
    border-left: 5px solid #ffab00 !important;
    border-radius: 5px !important;
  }
}
