.RadioBoxInput {
  &__option {
    padding: 1rem 0.3rem;
    background: transparent;
    border: 1px solid var(--color-grey);
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: 500;
    color: var(--emphasis-medium);
    font-size: 11px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &--selected {
      border-color: var(--color-focus);
      color: var(--color-focus);
    }
  }

  &--flex {
    display: flex;
    flex-wrap: nowrap;

    & > .RadioBoxInput__option {
      flex: 1;
    }
  }
}
