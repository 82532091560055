.DrawerIterationOverloadPage {
  &__title {
    font-weight: var(--font-weight-bold);
    font-size: 14px;
    color: var(--color-grey-even-darker);
    padding: 0 1.5rem;
  }

  &__description {
    font-size: 12px;
    color: var(--color-grey-even-darker);
    margin-bottom: 1.5rem;
    padding: 0 1.5rem;
  }

  &__buttons {
    margin: 1.5rem;
    padding: 0 1.5rem;
  }

  &__newButton {
    background: transparent;
    border: 1px solid black;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 1rem;

    & > svg {
      position: absolute;
      right: 1rem;
    }

    &:hover:not(&--flat) {
      background-color: var(--color-white);
    }
  }

  &__emptyState {
    color: var(--emphasis-medium);
    font-size: 12px;
    padding: 0 1.5rem;
  }
}
