.InputDateTime {
  --font-family: monospace;

  box-sizing: border-box;
  width: 100%;
  border: 1px solid;
  border-radius: 10px;
  color: var(--color-grey-dark);
  background-color: white;

  & > div[class=container]  {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px;

      & > div[class=item_1]  {
          display: flex;
          flex-direction: row;
          width: 100%;
          font-family: var(--font-family);
          font-size: var(--font-size-input);

          /* Firefox */
          & input[type=number] {
              -moz-appearance:textfield;
          }

          /* Chrome */
          & > input[class^="input_"]::-webkit-outer-spin-button, input[class^="input_"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }

          & > input[class^="input_"] {
              outline: none;
              border: 0;
              text-align: center;
              padding: 0;
              margin: 0;
              font-family: var(--font-family);
              font-size: var(--font-size-input);
              color: var(--color-grey-dark);
          }

          & > input[class=input_day] {
            width: 16px;
          }

          & > input[class=input_month] {
            width: 15px;
          }

          & > input[class=input_year] {
            width: 30px;
            text-align: left;
          }

          & > div[class=item_2]  {
              flex: 1;
              cursor: text;
          }
      }


      & > input[class=calander]  {
          outline: none;
          width: 18px;
          border: 0;
      }

  }
}
