.DrawerContentRespondants {
  padding: 1.5rem;

  &__title {
    font-weight: var(--font-weight-bold);
    font-size: 14px;
    color: var(--color-grey-even-darker);
  }

  &__description {
    font-size: 12px;
    color: var(--color-grey-even-darker);
    margin-bottom: 1rem;
  }
}
