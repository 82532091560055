.ContributeFormTemplate {
  padding: 2rem;
  padding-top: 4rem;
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;

  &__title {
    margin-top: 64px;
    margin-left: 24px;
    font-size: 32px;
  }

  &__actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 1rem;
    background: #efefef;
    box-sizing: border-box;
    align-items: center;

    &_meta {
      flex: 1;
      padding: 0 1rem;

      & > span {
        margin-right: 1.5rem;
      }
    }
  }

  &__hiddenSubmit {
    position: fixed;
    top: -1000px;
    left: -1000px;
  }
  &__submit {
    transition: border 0.5s ease;
    border-color: var(--color-primary);
  }
  &__submit:hover {
    border: 1px solid rgba(129, 148, 255, 1);
  }
  &__submit:focus {
    border: 1px solid rgb(48, 77, 252);
  }
}
