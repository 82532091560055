.Phone {
  /* height: var(--height); */
  display: flex;
  &__input {
    color: var(--color-font);
    letter-spacing: 0;
    line-height: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }

  &__indicatif {
    display: inline-block;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 140px;
  }

  &--error > input,
  &--error:focus > input {
    border-color: var(--color-error) !important;
    border-left: 1px solid var(--color-grey) !important;
  }

  &__optionFlag {
    height: 12px;
    margin-left: 2px;
    position: relative;
    top: 2px;
  }
}
