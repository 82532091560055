.Grid {
  height: 100%;
  max-width: 100%;
  position: relative;

  &__background {
    position: absolute;
    background-image: radial-gradient(circle at 2px 2px, var(--radio-color-2) 2px, transparent 0);
    top: 0;
  }
}
