.Timer {
  text-align: center;
  align-items: center;
  display: flex;
  font-size: '18px';

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
