.ContributeCloseButtonTemplate {
  &__back {
    position: absolute;
    top: 16px;
    left: 16px;
    border: 1px solid var(--color-font);
    background: var(--color-white);
    height: 1.8rem;
    z-index: 1000;

    &:hover:not(&--flat) {
      background-color: var(--color-white);
    }

    & > svg {
      margin-right: 8px;
    }
  }
}
