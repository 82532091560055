.SwitchToggle {
  display: flex;
  flex-direction: row;

  &__label_left,
  &__label_right {
    cursor: pointer;
    font-size: var(--font-size-input);
    color: var(--color-grey-even-darker);
    display: block;
    margin-bottom: 4px;
    user-select: none;
    font-weight: 500;
  }

  &__label_left {
    margin-left: 1rem;
  }

  &__label_right {
    margin-right: 1rem;
  }
}
