.Url {
  display: block;
  width: 100%;
  & > input {
    width: 100%;
  }
  &--error > input {
    border-color: var(--color-error);
  }
}
