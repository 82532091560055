.FormField {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--label {
      font-size: var(--font-size-input);
      color: var(--color-grey-even-darker);
      display: block;
      margin-bottom: 4px;
      user-select: none;
      font-weight: 500;
    }

    &--dropdown {
      width: 20%;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &--inline {
      flex-direction: row;
    }

    &--reverse {
      & > :nth-child(1) {
        order: 2;
      }
      & > :nth-child(2) {
        order: 1;
      }

      & > .FormField__header > .FormField__header--label {
        margin-left: 0.5rem;
      }
    }
  }

  &--inline {
    flex-direction: row;
  }

  &--reverse {
    & > :nth-child(1) {
      order: 2;
    }
    & > :nth-child(2) {
      order: 1;
    }

    & > .FormField__header > .FormField__header--label {
      margin-left: 0.5rem;
    }
  }
  &--verticalAlign {
    margin-bottom: 0;
    height: auto;
  }
  &--multi {
    height: calc(100% - 16px);
  }
}
