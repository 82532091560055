.Alert {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.Alert__modal {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 200px;
  font-size: 16px;
}

.Alert__actions {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
